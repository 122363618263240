import React from 'react';
import ReactDOM from 'react-dom';

import app from './firebase';

import { AuthProvider } from './Auth'
import { getAuth, signInWithPopup, GoogleAuthProvider, signOut, onAuthStateChanged } from "firebase/auth";
import User from './components/User';

class HelloMessage extends React.Component {
  signIn = () => {
    const auth = getAuth();
    const provider = new GoogleAuthProvider();

    signInWithPopup(auth, provider)
      .then((result) => {
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;

        const user = result.user;
      }).catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        const email = error.email;

        const credential = GoogleAuthProvider.credentialFromError(error);
      });
  };

  signOut = () => {
    const auth = getAuth();
    signOut(auth).then(() => {
      console.log("Sign-out successful");
    }).catch((error) => {
      // An error happened.
    });
  };

  render() {
    return (
      <AuthProvider>
        <div>
          <div className="container">
            <h1 onClick={this.signIn}>Hello {this.props.name}</h1>
            <h1 onClick={this.signOut}>log out</h1>
          </div>
          <User />
        </div>
      </AuthProvider>
    );
  }
}

let App = document.getElementById('app');

ReactDOM.render(<HelloMessage name="Tom" />, App);
