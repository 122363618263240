import React, { useContext } from 'react'

import { AuthContext } from '../Auth'

export default function User() {
  const auth = useContext(AuthContext)

  return (
    <div>
      {auth.currentUser?.displayName}
      <img src={auth.currentUser?.photoURL} />
    </div>
  )
}
