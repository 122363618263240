import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyBkbYQYp9jH_ntyn4a9jdEkHZJ35Neu5R8",
  authDomain: "ev3nt5.firebaseapp.com",
  databaseURL: "https://ev3nt5-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "ev3nt5",
  storageBucket: "ev3nt5.appspot.com",
  messagingSenderId: "1031318154522",
  appId: "1:1031318154522:web:0f139ea25b0ad089ea10a3"
};

const app = initializeApp(firebaseConfig);

export default app;
